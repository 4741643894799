import { render, staticRenderFns } from "./HeadingBox.vue?vue&type=template&id=4e4458c2&scoped=true&"
import script from "./HeadingBox.vue?vue&type=script&lang=ts&"
export * from "./HeadingBox.vue?vue&type=script&lang=ts&"
import style0 from "./HeadingBox.vue?vue&type=style&index=0&id=4e4458c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e4458c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkManual: require('/opt/build/repo/components/utilities/links/LinkManual.vue').default})
